@import "~bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

body  {
    --bs-font-sans-serif: "Montserrat", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    --bs-body-font-family: "Montserrat", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    --bs-success-rgb: 58, 196, 48;
    --bs-primary-rgb: 5, 105, 255;
    --bs-secondary-rgb: 227, 232, 234;
}

body .text-bg-secondary {
    color: black !important;
}

body .btn {
    --bs-btn-border-radius: 24px;
    --bs-btn-padding-x: 2em;
    --bs-btn-padding-y: 0.60em;
    --bs-btn-font-family: "Montserrat", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    --bs-btn-font-weight: 600;
}

body h2 {
    font-weight: bold;
}

body .btn.btn-primary {
    --bs-btn-bg: #E74C3C;
    --bs-btn-border-color: #E74C3C;
    --bs-btn-hover-bg: #ec7063;
    --bs-btn-hover-border-color: #ec7063;
}

.eo-vis-menu-bar-element .eo-vis-menu.eo-vis-menu--close {
    display: none !important;
}

.hints {
    background-color: #ebf3f4;
    color: #006276;
}

body .accordion {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23a8bac1%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23a8bac1%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

body .accordion .bg-secondary {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%231f3245%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%231f3245%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    opacity: 0.3;
}