.overlay-modal {
    background-color: white;
    width: 750px;
    margin: auto;
    padding: 50px;
    box-shadow: 4px 4px 15px #1F324526;
    border-radius: 4px;
}

.overlay-modal img {
    max-height: 200px;
}

.overlay-modal h2 {
    color: #e74c3c;
}

.overlay-modal p {
    color: #08303b;
}
