.background {
    width: 100vw;
    height: 100vh;
    background-image: url(../../public/flowable_work_banner_2800.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.container {
    margin: 0 1.5rem;
    padding: 2.5rem 0;
    min-height: 100vh;
}

.content {
    height: 100%;
}

.logo {
    width: 10rem;
    margin-bottom: 2rem;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0.5rem 1rem 2rem #0000004D;
    padding: 2.5rem 1.5rem;
    max-width: 450px;
    width: 100%;
}

.content h1 {
    font-size: 1.75rem;
    line-height: 2.75rem;
    color: #e74c3c;
    text-align: center;
    margin-bottom: 0.75rem;
    font-weight: bold;
}

.red-line {
    height: 6px;
    width: 2.625rem;
    margin: 0 auto;
    background: #e74c3c;
}

.headline {
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    align-self: flex-start;
}

@media screen and (min-width: 768px) {
    .container {
        max-width: 80%;
        margin: 0 auto;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    .logo {
        align-self: flex-start;
    }
}
@media screen and (min-width: 1024px) {
    .logo {
        margin-bottom: 0;
    }

    .form-container {
        margin-top: 10rem;
    }

    .headline, .intro {
        margin-top: 2.625rem;
    }
}